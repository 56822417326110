const Footer = () => {
  return (
    <div className="bg-deep-purple-900 text-white">
      <div className="">
        <h1 className="sm:px-9 text-center">© Copyright Imagine Infotech . All Rights Reserved</h1>

      </div>





    </div>
  )
}
export default Footer